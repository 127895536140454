var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50"
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "is-visible": true,
      "class-body": "p-0",
      "class-header": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title font-weight-bolder py-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "p-0",
    attrs: {
      "id": "invoice-list-filter"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "align-h": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.from')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.startDate,
      "placeholder": "".concat(_vm.$t('topup.placeholderSelectDate'), " DD/MM/YYYY"),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDate ? _vm.endDate : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:startDate', val);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.to')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.endDate,
      "placeholder": "".concat(_vm.$t('topup.placeholderSelectDate'), " DD/MM/YYYY"),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.startDate,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:endDate', val);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.agencyCode')
    }
  }, [_c('vue-autosuggest', {
    attrs: {
      "id": "agency-code",
      "value": _vm.agencyCode,
      "suggestions": _vm.agencySuggestions,
      "input-props": {
        placeholder: "".concat(_vm.$t('invoice.ph.agencyCode')),
        class: 'form-control'
      },
      "get-suggestion-value": _vm.getSuggestionValue
    },
    on: {
      "input": _vm.inputAgencyFilterHandle,
      "selected": function selected(val) {
        var _val$item, _val$item$agencyCode;
        return _vm.$emit('update:agencyCode', val === null || val === void 0 ? void 0 : (_val$item = val.item) === null || _val$item === void 0 ? void 0 : (_val$item$agencyCode = _val$item.agencyCode) === null || _val$item$agencyCode === void 0 ? void 0 : _val$item$agencyCode.toUpperCase());
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var suggestion = _ref.suggestion;
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "text-info text-uppercase"
        }, [_vm._v(_vm._s(suggestion.item.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(suggestion.item.agencyName) + ")")])])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.releaseStatus.title')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.releaseStatus,
      "options": _vm.RELEASE_STATUS,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:releaseStatus', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveReleaseStatus(data.label),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.releaseStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveReleaseStatus(data.label),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.releaseStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.signStatus.title')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.signStatus,
      "options": _vm.SIGN_STATUS,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:signStatus', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": "light-".concat(_vm.resolveSignStatus(data.label).color),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.signStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": "light-".concat(_vm.resolveSignStatus(data.label).color),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.signStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceStatus.title')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.invoiceStatus,
      "options": _vm.INVOICE_STATUS,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceStatus', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveInvoiceStatus(data.label),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveInvoiceStatus(data.label),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.buyer.name')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.buyerName,
      "placeholder": _vm.$t('invoice.ph.buyerName'),
      "formatter": _vm.trimInput,
      "lazy-formatter": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:buyerName', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.columns.invNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.invNumber,
      "placeholder": _vm.$t('invoice.ph.invNumber'),
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invNumber', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.referenceCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.referenceCode,
      "placeholder": _vm.$t('invoice.ph.referenceCode'),
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:referenceCode', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.tags')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.tagsFilter,
      "options": _vm.tagOptions,
      "multiple": "",
      "taggable": "",
      "push-tags": "",
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('invoice.create.info.phAssignTag'),
      "reduce": function reduce(val) {
        return val;
      },
      "loading": _vm.loadingTags
    },
    on: {
      "open": _vm.onOpenTagHandle,
      "search": _vm.onSearchTagHandle,
      "input": _vm.onInputTagHandle
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }, {
      key: "spinner",
      fn: function fn(_ref2) {
        var loading = _ref2.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "flex-fill",
    attrs: {
      "cols": "12",
      "md": "auto",
      "align-self": "end"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end gap-1"
  }, [_c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.refetchData
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "search",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "delete",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.clearFilter')) + " ")], 1)])], 1)])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }